import log from "loglevel";
import { QueryClient } from "react-query";
import { CLOSED_BETA_SIFTS, IAM_URL, TILL_URL } from "./constants";

export const login = () => {
  window.location.href = `${IAM_URL}/login?redirectapp=cloud&redirectpath=${encodeURIComponent(
    window.location.pathname + window.location.search + window.location.hash
  )}`;
};

export const logout = async () => {
  await fetch(IAM_URL + "/api/logout", { credentials: "include" })
    .then((res) => log.debug("utils::logout::res", res))
    .catch((err) => log.error("utils::logout::err", err));
  window.location.href = `${IAM_URL}/logout?redirectapp=cloud&redirectpath=${encodeURIComponent(
    window.location.pathname + window.location.search + window.location.hash
  )}`;
};

export const profile = () => (window.location.href = `${IAM_URL}/profile`);

export const showOAuthPopup = async (
  ctx: any,
  siftJwt: string,
  provider: string,
  siftId: string,
  guid: string,
  options: any
) => {
  const { code } = await fetch(IAM_URL + `/api/oauthcode`, {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      provider,
      redirectapp: "cloud",
      siftId,
      guid,
      redirectpath: window.location.pathname,
    }),
  })
    .then((res) => res.json())
    .catch((err) => log.error("utils::showSlackAuth::err", err));

  if (!code) {
    log.error("utils::showSlackAuth:could not get code");
    ctx.setAlert("Failed to retrieve code from IAM", "error");
    return;
  }
  window.location.href = IAM_URL + `/oauth?oauthCode=${code}`;
};

export const showSlackAuth = async (ctx: any, siftId: string, guid: string) => {
  const { code } = await fetch(IAM_URL + `/api/oauthcode`, {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      provider: "slack",
      redirectapp: "cloud",
      siftId,
      guid,
      redirectpath: window.location.pathname,
    }),
  })
    .then((res) => res.json())
    .catch((err) => log.error("utils::showSlackAuth::err", err));

  if (!code) {
    log.error("utils::showSlackAuth:could not get code");
    ctx.setAlert("Failed to retrieve code from IAM", "error");
    return;
  }
  window.location.href = IAM_URL + `/oauth?oauthCode=${code}`;
};

export const showTill = (siftId: string, guid: string) => {
  window.location.href = TILL_URL + `/?id=${siftId}&guid=${guid}`;
};

export const requestShortlivedCode = async (value?: any) => {
  const { redirectapp, redirect_uri } = value;
  const { code, userId, error } = await fetch(IAM_URL + `/api/code`, {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      redirectapp,
      redirect_uri,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      log.error("utils::requestShortlivedCode::err", error);
      return { error };
    });
  return {
    code,
    userId,
    error,
  };
};

export const showOAuthRemovePopup = async (
  ctx: any,
  siftId: string,
  guid: string,
  provider: string
) => {
  await fetch(IAM_URL + `/api/sifts/${siftId}/${guid}/identity/remove`, {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      provider,
    }),
  })
    .then((res) => {
      log.debug("utils::showOAuthRemovePopup::res", res);
      // NOTE: elegant solution here would be to message the sift about completion
      // for now, cannon approach
      window.location.reload();
    })
    .catch((err) => {
      log.error("utils::showOAuthRemovePopup::err", err);
      ctx.setAlert("Failed to retrieve code from IAM", "error");
    });
};

export const invalidateAllQueries = async (queryClient: QueryClient) => {
  // Note: queries should be invalidated in this order for consistency
  await queryClient.invalidateQueries(["iam_jwt"]);
  await queryClient.invalidateQueries(["sift_jwt"]);
  await queryClient.invalidateQueries(["user"]);
  await queryClient.invalidateQueries(["sifts"]);
};

export const isAdmin = (user: any) => {
  const isRedSift = user?.email && user?.email.endsWith("@redsift.io");
  const isVerified = user?.emailVerified;
  const isAdmin = user?.attributes?.isAdmin;

  if ((!isRedSift || !isVerified) && isAdmin) {
    log.error(
      "utils::isAdmin::critical, non Red Sift user has isAdmin flag",
      user.id
    );
  }

  return isRedSift && isVerified && isAdmin;
};

export const hasAccessToHardenize = (user: any) => {
  return user?.hasAccessToHardenize || false;
};

export const isCustSupport = (user: any) => {
  const isRedSift = user?.email && user.email.endsWith("@redsift.io");
  const isVerified = user?.emailVerified;
  const isCustSupport = user?.attributes?.isCustSupport;

  if ((!isRedSift || !isVerified) && isCustSupport) {
    log.error(
      "utils::isAdmin::critical, non Red Sift user has isAdmin flag",
      user.id
    );
  }

  return isRedSift && isVerified && isCustSupport;
};

export const hasTools = (user: any) => {
  return !!user?.attributes?.tools?.length;
};

export const hasClosedBetaAccess = (user: any) => {
  return (
    Boolean(user?.attributes?.closedBetaSifts?.length) ||
    user?.sifts.some((sift: any) => {
      return CLOSED_BETA_SIFTS[sift.guid];
    })
  );
};

export const getIam = async (path: string, params: any = {}) => {
  let url = new URL(IAM_URL + path);
  Object.keys(params).forEach(
    (k) => params[k] && url.searchParams.append(k, params[k])
  );
  return await fetch(url.toString(), {
    credentials: "include",
  })
    .then((res) => res.json())
    .catch((e) => {
      log.error("utils::getIam::", e);
      return { error: `failed to get ${path}` };
    });
};

export const postIam = async (
  path: string,
  body: any,
  contentType?: string,
  params?: any
) => {
  return postPutIam("POST", path, body, contentType, params);
};

export const putIam = async (
  path: string,
  body: any,
  contentType?: string,
  params?: any
) => {
  return postPutIam("PUT", path, body, contentType, params);
};

const postPutIam = async (
  method: string,
  path: string,
  body: any,
  contentType = "application/json",
  params: any = {}
): Promise<any> => {
  let url = new URL(IAM_URL + path);
  Object.keys(params).forEach(
    (k) => params[k] && url.searchParams.append(k, params[k])
  );
  return await fetch(url.toString(), {
    method,
    credentials: "include",
    body: contentType === "application/json" ? JSON.stringify(body) : body,
    headers: {
      "Content-Type": contentType,
    },
  })
    .then((res) => (res.status === 204 ? {} : res.json()))
    .catch((e) => {
      log.error("utils::postPutIam::", method, e);
      return { error: `failed to ${method} to ${path}` };
    });
};

export const getExcludedApps = (config: any = {}, sifts: any = {}) => {
  const { excludeApps = [] } = config;
  const ret = [];
  for (const e of excludeApps) {
    if (!sifts[e]) {
      ret.push(e);
    }
  }

  return ret;
};

export const canMultiInstanceAdmin = (user: any, guid: string) => {
  // Multi instance administration of OnINBOX is via OnINBOX manager
  return (
    user.attributes?.multiInstanceAdmin &&
    guid !== "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1"
  );
};

export const isLocalStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, "");
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
